$main-font-color: #1f232b;
$main-font-color-2: #5a5c69;
$secondary-font-color: rgba(15, 49, 146, 1.0);
$main-bg-color: #f9f9fc;
$card-border: #e3e6f0;
$main-light-font: #d1d3e2;
$secondary-light-font: #8a8b92;

.landing-page-container {
  font-family: 'metric', Helvetica, Arial, sans-serif;

  h2 {
    color: #111128;
  }

  section {
    padding: 100px 0
  }

  blockquote {
    font-weight: normal;
    font-size: 19px;
    line-height: 1.5;
    color: #646E7F;
    margin-left: auto;
    margin-right: auto
  }

  @media (min-width: 992px) {
    blockquote {
      max-width: 620px
    }
  }

  .text-muted {
    color: #ABB0C1 !important
  }
}



.wrapper {
  //padding-top: 60px;
  -ms-flex: 1 1;
  flex: 1 1;
  display: -ms-flexbox;
  display: flex;
  z-index: 8;
  position: relative;

  .main-content {
    z-index: 9;
    padding: 0;
    font-family: "Lato", sans-serif;

    h1, h2, h3, h4, h5, h6 {
      font-family: "Lato", sans-serif;
      font-weight: 600;
      color: $main-font-color;
    }

    .smallFab {
      width: 48px;
      height: 48px;
    }

    .main-container {
      background-color: $main-bg-color;
      padding: 20px 40px;
      min-height: 100vh;

      .row {
        margin-top: 0;
      }

      .charts-row {
        margin-top: 30px;
      }

      .main-card {
        .react-bs-table {
          border-radius: 0.35rem;
          margin: 30px 0;
          border: 1px solid $card-border;
          box-shadow: none;

          .tr-row-expand {
            font-weight: 700;

            td {
              height: 49px;
            }
          }

          .table-header-wrapper {
            table thead {
              border-radius: calc(.35rem - 1px) calc(.35rem - 1px) 0 0;
              line-height: 1.5;
              margin-bottom: 0;
              padding: 15px 20px;
              border-bottom: 1px solid $card-border;
              background-color: $main-bg-color;
              color: $secondary-font-color;
            }
          }

          .expandedTableRow td {
            margin: 0;
            padding: 0;
            border: none;
            align-items: center;


            .expandedTable .react-bs-table {
              margin: 0;
              border: none;

              .table-header-wrapper {
                display: none;
              }

              .react-bs-container-body {
                background-color: #f8f8f8;

                td {
                  padding: 0.75rem;
                  border: 1px solid #dee2e6;
                }
              }
            }
          }
        }
      }
    }

    .thick-800 {
      font-weight: 800;
    }

    .up-change {
      color: rgba(73, 220, 177, 1);
    }

    .down-change {
      color: rgba(255, 99, 132, 1);
    }

    .no-change {
      color: rgba(76, 86, 192, 1)
    }
  }
}

//
//.btn {
//  font-weight: 600;
//}
//
//.btn-primary {
//  background-color: #4C56C0;
//  border-color: #4C56C0;
//
//  &:not(:disabled):not(.disabled):active, &:active {
//    border-color: #4851b3;
//    background-color: #4851b3;
//  }
//
//  &:hover,
//  &:focus {
//    border-color: #4851b3;
//    background-color: #4851b3;
//  }
//}
//
//.btn-remove {
//  background-color: rgba(255, 99, 132, 1);
//  border-color: rgba(255, 99, 132, 1);
//
//  &:not(:disabled):not(.disabled):active, &:active {
//    border-color: #4851b3;
//    background-color: #4851b3;
//  }
//
//  &:hover,
//  &:focus {
//    border-color: #4851b3;
//    background-color: #4851b3;
//  }
//}

.icon-btn {
  padding: 0;
  background: none;
  border: none;
  color: #4C56C0;

  &:not(:disabled):not(.disabled):active, &:active {
    color: #1420a0;
    background: none;
  }

  &:hover,
  &:focus {
    color: #1420a0;
    background: none;
  }
}

.icon-btn.red {
  color: #ff6384;

  &:not(:disabled):not(.disabled):active, &:active {
    color: #ff2b58;
  }

  &:hover,
  &:focus {
    color: #ff2b58;
  }
}

.icon-btn.green {
  color: #49DCB1;

  &:not(:disabled):not(.disabled):active, &:active {
    color: #4bff93;
  }

  &:hover,
  &:focus {
    color: #4bff93;
  }
}

.material-icons {
  display: inline-flex;
  vertical-align: middle;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}


@media screen and (max-width: 600px) {
  .wrapper .main-content {
    padding-left: 0;
  }
}

.error {
  color: #ff6384;
  font-size: 12px;
  font-weight: bold;
}

.entry-row {
  position: relative;
  .remove-btn {
    position: absolute;
    right: 0;
    top: 25px;
    .btn {
      padding: 0;
    }
  }
  .edit-btn {
    position: absolute;
    right: 0;
    top: 30px;
    .btn {
      padding: 0;
    }
  }
  .refinance-btn {
    position: absolute;
    right: 30px;
    top: 0;
    .btn {
      padding: 0;
    }
  }
}

.MuiTooltip-tooltip {
  font-size: 13px !important;
}

.MuiToolbar-gutters {
  padding-left: 16px !important;
}

.react-tabs__tab-panel--selected { animation-name: fadeIn; animation-duration: 250ms; animation-timing-function: linear; }

@keyframes fadeIn { from { opacity: 0.5; } to { opacity: 1; } }
