@import '~sass-material-colors/sass/sass-material-colors';

$sidebar-bg: #171b37;
$sidebar-accent-bg: rgb(35, 47, 62);
$header-font-accent: material-color('blue', '400');
$sidebar-font-col: rgba(255, 255, 255, 0.8);
$card-border: rgba(227, 230, 240, 0.1);

.header {
  position: fixed;
  width: 240px;
  top: 0;
  height: 60px;
  display: flex;
  align-items: center;
  align-content: center;
  z-index: 10;
  transition: all .2s;
  background: rgba(255, 255, 255, 1);

  .sign-out-btn {
    margin-right: 40px;
  }

  .brand-logo {
    padding: 10px;
    height: 60px;
    width: 240px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: width .2s;
    -o-transition: width .2s;
    transition: width .2s;
    visibility: visible;
    background-color: $sidebar-bg;
    text-align: left;
    border-bottom: 1px solid $card-border;

    h5 {
      margin: 0 0 0 10px;
      font-weight: 700;
      color: #FFF;
      font-size: 16px;
    }

    i {
      color: $header-font-accent;
    }

    .hamburger {
      display: none;
      background-color: transparent;
      border: none;
      color: white;
      padding: 0;
      font-size: 16px;
      cursor: pointer;
      height: 24px;

      i {
        color: #fff;
        vertical-align: middle;
      }
    }
  }
}

.sidebar {
  width: 240px;
  display: flex;
  z-index: 11;
  overflow: hidden;
  min-width: 240px;
  flex: 0 0 240px;
  padding-top: 60px;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  position: fixed;
  height: 100vh;
  background-color: $sidebar-bg;
  color: $sidebar-font-col;

  aside {
    width: 100%;
  }

  .sidebar-footer {
    bottom: 10px;
    clear: both;
    display: block;
    padding: 5px 15px;
    position: fixed;
    width: 240px;
    z-index: 999;

    .btn {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .header {
    width: 100%;

    .brand-logo {
      width: 100%;

      .hamburger {
        display: block;
      }
    }


  }
  .sidebar {
    width: 100%;
    display: none;
    z-index: 11;
    overflow: hidden;
    flex: 0 0 240px;
    padding-top: 60px;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    position: fixed;
    height: 100vh;
    background-color: $sidebar-bg;
    color: $sidebar-font-col;

    aside {
      width: 100%;
    }

    .sidebar-footer {
      width: 100%;
    }
  }
}

@media screen and (min-width: 600px) {
  .sidebar {
    display: flex !important;

    aside {
      width: 100%;
    }
  }
}
